// Here you can add other styles

.category-options {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.8s;
  &:hover {
    opacity: 1;
  }
}

.category-loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.category-icon {
  width: 2rem !important;
  height: 2rem !important;
  cursor: pointer;
}

.product-item {
  border-radius: 0.75rem !important;
}

.text-error {
  color: #e55353 !important;
}

.card-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.card-shadow:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

html:not([dir="rtl"]) .form-check-input {
  margin-left: unset;
}

.PhoneInput {
  &:active {
    color: #768192;
    background-color: #fff;
    border-color: #49a3f4;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(11, 99, 178, 0.25);
  }
}

.PhoneInputInput {
  outline: none;
  border: 0px;
  // height: calc(1.5em + 0.75rem);
  // overflow: hidden;
  // border-top-left-radius: 4px;
  // border-bottom-left-radius: 4px;
  // &:focus {
  //   color: #768192;
  //   background-color: #fff;
  //   border-color: #49a3f4;
  //   outline: 0;
  //   box-shadow: 0 0 0 0.2rem rgba(11, 99, 178, 0.25);
  // }
}

.countdown {
  position: fixed;
  z-index: 999;
  inset: 0;

  &__overlay {
    position: fixed;
    inset: 0;
    background-color: black;
    z-index: 999;
    overflow: hidden;
    filter: opacity(0.7);
  }

  &__container {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    padding: 0 12px;
  }

  .c-avatar {
    z-index: 999999;
    width: 4rem;
    margin-bottom: 1rem;
  }

  .card {
    z-index: 999999;
    max-width: 40rem;
  }

  &__items {
    display: flex;
    gap: 10px;
    justify-content: center;
  }

  &__item {
    text-align: center;
    color: $secondary;
    padding: 0.5rem 1rem;
    width: 6rem;
    border: 1px solid $primary;
    border-radius: 8px;

    @media (max-width: 450px) {
      width: 5.5rem;
      font-size: 0.8rem;
    }
  }

  &__item span {
    font-size: 2rem;
    font-weight: bold;
    color: $primary;

    @media (max-width: 450px) {
      font-size: 1rem;
    }
  }
}
