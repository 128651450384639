$download: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-download'%3E%3Cpath d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4'%3E%3C/path%3E%3Cpolyline points='7 10 12 15 17 10'%3E%3C/polyline%3E%3Cline x1='12' y1='15' x2='12' y2='3'%3E%3C/line%3E%3C/svg%3E";

$remove: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E";

.dropzone {
  border: 2px dashed $primary;
  background: $body-bg;
  position: relative;
  display: flex;
  cursor: pointer;
  min-height: 180px;
  align-items: "center";
  justify-content: center;
  background-color: $body-bg;
  border-radius: $border-radius;
  margin-bottom: 1.5rem;
  // dropzone message customization
  .dz-message {
    font-size: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #7367f0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  // dropzone message icon
  .dz-message:before {
    content: "";
    background-image: url(str-replace(
      str-replace($download, "currentColor", $primary),
      "#",
      "%23"
    ));
    font-size: 80px;
    position: absolute;
    top: 14rem;
    width: 80px;
    height: 80px;
    display: inline-block;
    line-height: 1;
    z-index: 2;
    color: $primary;
    text-indent: 0px;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
  }

  // for preview of files
  .dz-preview {
    background: transparent !important;

    .dz-error-mark,
    .dz-success-mark {
      z-index: 10;
    }

    .dz-image {
      border-radius: $border-radius;
    }
    &.dz-image-preview {
      background: transparent !important;
    }

    .dz-error-message {
      background: $danger;
      &:after {
        border-bottom: 6px solid $danger;
      }
    }
  }

  // for dropzone preview and remove icon
  .dz-preview .dz-remove {
    font-size: 1.1rem;
    color: $danger;
    line-height: 2rem;

    &:before {
      content: "";
      background-image: url(str-replace(
        str-replace($remove, "currentColor", $danger),
        "#",
        "%23"
      ));
      display: inline-block;
      line-height: 1;
      z-index: 2;
      text-indent: 0px;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
    }

    &:hover {
      text-decoration: none;
      color: darken($danger, 10%);
    }
  }
}

// For Small Screen drop Logo
@media (max-width: 576px) {
  .dropzone {
    .dz-message {
      font-size: 1.5rem;
      &:before {
        top: 15rem;
        width: 50px;
        height: 50px;
      }
    }
  }
}
