// todo: disabled button styles
button {
  &:disabled {
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}
// todo: brand button icon margin
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}
.c-sidebar {
  background: #0b63b2;
}
